"use client";

import Image from "next/image";
import err404 from "../../public/404.webp";
import { sizes } from "../styles/sizes";

const Error404: React.FC = () => {
  return (
    <div
      dir="ltr"
      style={{
        padding: "20px 0px",
        position: "relative",
        width: sizes.viewWidth,
        aspectRatio: "1 / 1",
      }}
    >
      <Image
        fill
        style={{
          margin: "auto",
          display: "block",
          border: "1px solid",
          borderRadius: "3px",
        }}
        alt="404 Error Page Not Found"
        src={err404.src}
      />
    </div>
  );
};

export default Error404;
